import { Box, type SpacingAlias } from "@yahoo/uds";
import { I13nAnchor } from "@yahoo-commerce/i13n";
import ShoppingLogoSvg from "@/svgs/shoppingLogo.svg";

interface ShoppingLogoProps {
  spacingBottom?: SpacingAlias;
}

export const ShoppingLogo = ({ spacingBottom = "6" }: ShoppingLogoProps) => {
  return (
    <Box spacingBottom={spacingBottom}>
      <I13nAnchor
        href="https://shopping.yahoo.com/"
        aria-label="Yahoo Shopping Home"
        dataYlk={{
          elm: "logo",
          slk: "yahoo shopping",
        }}
      >
        <ShoppingLogoSvg />
      </I13nAnchor>
    </Box>
  );
};

export default ShoppingLogo;
